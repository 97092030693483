{
  "daytime": {
    "earlier": "AM",
    "later": "PM"
  },
  "login": {
    "title": "Iniciar session",
    "text": "Iniciar session ath tòn compde",
    "button": "Iniciar session",
    "stressLevel": "Stress Level",
    "errorDialog": {
      "message": "Nòm d'usatgèr o contrasenya incorrècta!",
      "accept": "D'acòrd"
    },
    "placeholder": {
      "username": "Se te platz, introdusís eth tòn nòm d'usatgèr",
      "password": "Se te platz, introdusís era tua contrasenya"
    }
  },
  "sidebar": {
    "bulletins": "Avalanche.report",
    "dangerSources": "Danger Sources",
    "observations": "Observacions",
    "qfa": "QFA",
    "modelling": "Modelitzacion",
    "modellingForecast": "Prediccion",
    "modellingZamg": "Modèl multiple ZAMG",
    "modellingZamgECMWF": "ZAMG ECMWF-EPS",
    "modellingZamgCLAEF": "ZAMG CLAEF-EPS",
    "modellingZamgMeteogram": "meteograma GeoSphere",
    "modellingSnowpack": "Modèl SNOWPACK",
    "modellingSnowpackMeteo": "Modèl meteo SNOWPACK",
    "weatherbox": "Weatherbox",
    "awsome": "AWSOME",
    "snowpack": "SNOWPACK",
    "geosphere": "GeoSphere"
  },
  "menu": {
    "logout": "Barrar session",
    "language": "Lengua",
    "en": "Anglés",
    "de": "Alemand",
    "fr": "Francés",
    "it": "Italian",
    "es": "Castelhan",
    "ca": "Catalan",
    "oc": "Aranès",
    "fullscreen": "Pantalha complèta",
    "settings": "Configuracion",
    "statistics": "Estadístiques",
    "admin": "Administrador",
    "region": "Region",
    "education": "Educacion"
  },
  "matrix": {
    "label": {
      "snowpackStability": "Estabilitat deth celh de nhèu",
      "frequency": "Frecuéncia",
      "avalancheSize": "Mida de lauet",
      "dangerRating": "Grad de perilh",
      "override": "(modifique eth grad de perilh)",
      "matrix": "Mairitz",
      "referToFairStability": "Referir-se a una estabilitat regular",
      "referToPoorStability": "Referir-se a una pobre estabilitat",
      "dangerLevel1": "Grad de perilh 1 (Fèble)"
    }
  },
  "dangerSources": {
    "status": {
      "missing": "absent",
      "available": "available",
      "forecast": "forecast",
      "analysis": "analysis",
      "loading": "Loading danger sources ...",
      "saveError": "Danger source could not be saved!",
      "loadDangerSourcesError": "Danger sources could not be loaded!",
      "loadVariantsError": "Danger source variants could not be loaded!"
    },
    "variantStatus": {
      "active": "active",
      "dormant": "dormant",
      "inactive": "inactive"
    },
    "tooltip": {
      "read": "Read danger sources",
      "edit": "Edit danger sources"
    },
    "table": {
      "loadMore": "Load {{count}} more days",
      "title": {
        "forecast": "Prediccion",
        "analysis": "Analysis"
      }
    },
    "create": {
      "variantStatus": "Status",
      "variantEditable": "This variant can be edited.",
      "variantNotEditable": "This variant can not be edited.",
      "noVariantsForSelectedDay": "No variants defined for this date.",
      "tooltip": {
        "editDangerSource": "Edit danger source",
        "loadVariants": "Load variants from the day before",
        "edit": "Editar micro-regions",
        "copy": "Copy variant",
        "delete": "Delete variant",
        "compare": "Compare variant",
        "forecast": "Compare with forecast"
      },
      "createDangerSource": "Create danger source",
      "createVariant": "Create new variant",
      "noDangerSources": "No danger sources",
      "title": {
        "avalanche": "Lauet",
        "matrix": "Mairitz",
        "characteristics": "Caracteristiques"
      },
      "label": {
        "glidingSnowActivity": "Gliding snow activity",
        "snowHeight": "Snow height",
        "zeroDegreeIsotherm": "0°C isotherm",
        "looseSnowMoisture": "Loose snow moisture",
        "looseSnowGrainShape": "Loose snow grain shape",
        "slabGrainShape": "Slab grain shape",
        "slabThickness": "Slab thickness",
        "slabHandHardness": "Slab hand hardness",
        "slabHardnessProfile": "Slab hardness",
        "slabEnergyTransferPotential": "Slab energy transfer potential",
        "slabDistribution": "Slab distribution",
        "weakLayerGrainShape": "Weak layer grain shape",
        "weakLayerGrainSize": "Weak layer grain size",
        "weakLayerPersistent": "Weak layer persistent",
        "weakLayerThickness": "Weak layer thickness",
        "weakLayerStrength": "Weak layer strength",
        "weakLayerWet": "Weak layer wet",
        "weakLayerCrustAbove": "Weak layer crust above",
        "weakLayerCrustBelow": "Weak layer crust below",
        "weakLayerPosition": "Weak layer position",
        "weakLayerCreation": "Weak layer creation",
        "weakLayerDistribution": "Weak layer distribution",
        "dangerSpotRecognizability": "Danger spot recognizability",
        "remoteTriggering": "Remote triggering",
        "hasDaytimeDependency": "Dependéncia ora deth dia",
        "dangerIncreaseWithElevation": "Danger increase with elevation",
        "runoutIntoGreen": "Runout into green",
        "highestDangerAspect": "Highest danger aspect",
        "dangerPeak": "Danger peak",
        "slopeGradient": "Grad d'inclinacion",
        "naturalRelease": "Natural release",
        "terrainTypes": "Terrain types",
        "dangerSigns": "Danger signs",
        "aspects": "Orientacions"
      },
      "editDangerSourceDialog": {
        "save": "Sauvar",
        "cancel": "Anullar"
      }
    }
  },
  "avalancheType": {
    "title": "Tipe de lauet",
    "label": {
      "slab": "SLAB",
      "loose": "LOOSE",
      "glide": "GLIDE"
    },
    "tooltip": {
      "slab": "Slab avalanche",
      "loose": "Loose snow avalanche",
      "glide": "Glide snow avalanche"
    }
  },
  "elevation": {
    "title": "Nautada",
    "high": "Enjós",
    "low": "Dessús",
    "treeline": "Limit deth bòsc"
  },
  "bulletins": {
    "title": "Avalanche.report",
    "tooltip": {
      "edit": "Edit bulletin",
      "read": "Read bulletin",
      "copy": "Copiar bulletin",
      "paste": "Colar bulletin",
      "cancel": "Cancelar copia",
      "update": "Editar",
      "submit": "Enviar",
      "submitUpdate": "Enviar actualizacion",
      "publishChange": "Publicar ara (sense messatges)",
      "publishUpdate": "Publicar ara",
      "caaml": "Mostrar CAAML",
      "json": "Mostrar JSON",
      "create": "Crear bulletin",
      "preview": "Vista previa deth bulletin (PDF)",
      "check": "Verificar bulletin",
      "info": "Mostrar er estat de publicacion",
      "publishAll": "Publicar totes es regions",
      "mediaFile": "Cargar un fichèr multimèdia"
    },
    "status": {
      "submittedInfo": "Serà publicat tàs 5PM",
      "resubmittedInfo": "Serà publicat tàs 8AM",
      "publishedInfo" : "A estat publicat",
      "noPublicationInfo" : "NON serà publicat automaticaments",
      "loadingPreview": "Creant ua vista prèvia de PDF...",
      "loading": "En tot cargar eth bulletin ...",
      "loadMoreBulletins": "Load {{count}} more bulletins",
      "publishing": "Bulletin publicant-se",
      "submitting": "Bulletin enviant-se",
      "saveError": "Bulletin could not be saved!",
      "loadBulletinsError": "Bulletins could not be loaded!"
    },
    "create": {
      "tooltip": {
        "edit": "Editar micro-regions",
        "copy": "Copiar region",
        "delete": "Esborrar region",
        "undo": "Undo last change in region",
        "redo": "Redo change in region",
        "createRegion": "Crear region",
        "cancel": "Anullar",
        "acceptSuggestion": "Acceptar regions suggerides",
        "rejectSuggestion": "Refusar regions suggerides",
        "saveChanges": "Sauvar cambiaments",
        "discardChanges": "Escartar cambiaments",
        "incompleteAvalancheProblem": "Problèma de lauegi incomplèt",
        "saveBulletin": "Sauvar bulletin",
        "discardBulletin": "Escartar cambiaments deth bulletin",
        "loadBulletin": "Cargar bulletin deth dia anterior",
        "uploadJsonBulletin": "Cargar eth bulletin en format JSON",
        "downloadJsonBulletin": "Descargar eth bulletin en format JSON",
        "daytimeDependency": "Dependéncia ora deth dia",
        "treeline": "Limit deth bòsc",
        "tendency": {
          "decreasing": "Perilh de lauegi diminuís",
          "steady": "Perilh de lauegi seguís parièr",
          "increasing": "El perill d’allaus aumente",
          "danger": "Perilh de lauegi"
        },
        "textcat": {
          "edit": "Editar",
          "copy": "Copiar",
          "paste": "Colar",
          "example": "Cargar tèxte exemple",
          "delete": "Esborrar"
        },
        "moveUpAvalancheProblem": "Eth problèma de lauegi se mostrarà ath bulletin",
        "moveDownAvalancheProblem": "Botjar eth problèma de lauegi cap enjós",
        "dangerRatingDirectionUp": "Cap ensús",
        "dangerRatingDirectionDown": "Cap enjós"
      },
      "save": "Sauvar",
      "discard": "Escartar",
      "back": "Endarrere",
      "createAggregatedRegion": "Naua region",
      "pasteAggregatedRegion": "Enganchar era region",
      "pendingSuggestions": "Suggeriments",
      "rejectSuggestion": "Rebutjar",
      "acceptSuggestion": "Acceptar",
      "cancelCopyBulletin": " Cancelar",
      "createAvalancheProblem": "Nau problèma",
      "ownRegions": "Regions pròprias",
      "foreignRegions": "Regions estrangères",
      "externalRegions": "Regions extèrnes",
      "mapSelectRegion": "Seleccionar regions ath mapa",
      "statusHeader": "Estat deth bulletin",
      "suggestions": "Suggeriments",
      "noBulletinsForSelectedDay": "Non s'a pas definit cap de region per aguesta data.",
      "autosave": "Sauvat automaticaments",
      "regionEditable": "Aguesta region pòt editar-se.",
      "regionNotEditable": "Aguesta region non pòt pas editar-se.",
      "regionLocked": "Aguesta region ei bloquejada.",
      "changeDangerRatingElevation": "Extendre eth perilh de lauegi",
      "mapTop": "Mapa dessús",
      "mapLeft": "Mapa en costat",
      "showNotes": "Mostrar nòtes",
      "hideNotes": "Amagar nòtes",
      "incompleteAvalancheProblem": "Incomplete",
      "label": {
        "avalancheProblems": "Problèma de lauegi",
        "aspects": "Orientacions",
        "highlights": "Alèrte especiau",
        "avActivityHighlights": "Titular dera situacion de lauegi",
        "avActivityComment": "Descripcion deth perilh de lauegi",
        "snowpackStructureComment": "Descripcion deth celh de nhèu",
        "dangerPatterns": "Patrons de perilh",
        "tendencyComment": "Descripcion de tendéncia",
        "notes": "Nòtes"
      },
      "copyRegion": {
        "label": "Copiar era region d'alèrta a un aute bulletin",
        "select": "Enganchar a ...",
        "currentBulletin": "Aguest bulletin"
      },
      "title": {
        "avalancheProblem": "Problèmes de lauegi",
        "dangerDescription": "Descripcion deth perilh de lauegi",
        "forenoon": "Maitin",
        "afternoon": "Tarde",
        "snowpackStructure": "Estructura deth celh de nhèu",
        "tendency": "Tendéncia",
        "showTranslations": "Mostrar revirades",
        "hideTranslations": "Amagar revirades"
      },
      "placeholder": {
        "notes": "Escriu ací ..."
      },
      "pmDialog": {
        "noIFrameSupport": "Eth tòn navigador non ei compatible damb IFrames."
      },
      "discardDialog": {
        "message": "Vòs escartar es cambiaments?",
        "accept": "Òc",
        "reject": "Non"
      },
      "deleteAggregatedRegionDialog": {
        "message": "Vòs esborrar aquesta region?",
        "accept": "Òc",
        "reject": "Non"
      },
      "noRegionDialog": {
        "message": "Aumens ua region cau èster tua.",
        "accept": "D'acòrd"
      },
      "loadDialog": {
        "message": "Vòs cargar es bulletins deth dia anterior?",
        "accept": "Òc",
        "reject": "Non"
      },
      "loadAutoSaveDialog": {
        "message": "Vòs cargar es bulletins sauvadi automaticaments?",
        "accept": "Òc",
        "reject": "Non"
      },
      "saveErrorDialog": {
        "message": "S'a produsit un error en sauvar es bulletins!",
        "accept": "D'acòrd"
      },
      "changeErrorDialog": {
        "message": "S'a produsit un error en cambiar es bulletins!",
        "accept": "D'acòrd"
      },
      "loadingErrorDialog": {
        "message": "S'a produsit un error en cargar es bulletins!",
        "accept": "D'acòrd"
      },
      "loadingJsonFileErrorDialog": {
        "message": "S'a produsit un error en cargar es bulletins dempús deth fichèr JSON!",
        "accept": "D'acòrd"
      },
      "avalancheProblemErrorDialog": {
        "message": "Manque eth problèma de lauegi, orientacions, estabilitat deth celh de nhèu, frequéncia e/o era mesura de lauet per aumens un problèma de lauegi",
        "accept": "D'acòrd"
      },
      "loadAvActivityCommentExampleTextDialog": {
        "message": "Entà quina situacion vòs cargar un tèxte d'exemple?",
        "newSnow": "Nhèu recenta",
        "windSlab": "Plaques de vent",
        "persistentWeakLayers": "Coches fèbles persistentes",
        "wetSnow": "Nhèu umida",
        "glidingSnow": "Esguitlaments basaus",
        "favourableSituation": "Situacion favorabla",
        "cancel": "Anullar"
      },
      "removeDaytimeDependencyDialog" : {
        "message": "Which avalanche problems do you want to keep?",
        "cancel": "Anullar"
      },
      "strategicMindsetDialog" : {
        "title": "Strategic Mindsets",
        "introduction": "The \"Strategic Mindsets\" are intended to express our mental attitude with regard to the avalanche situation and its development in the corresponding warning region. It should not be derived directly from the hazard assessment, but rather understood and used as an intuitive impression of the overall situation. The \"Strategic Mindsets\" are therefore primarily a communication tool. Using the mindsets and associated definitions, we forecasters can quickly and efficiently exchange information about the development of the hazard situation and find a common language for communication.",
        "description": {
          "title": "Description:",
          "text": {
            "assessment": "There is <b>great uncertainty</b> in the risk assessment due to <b>insufficient information</b> from the site.",
            "stepping_out": "<b>Decreasing tendency</b> of the hazard situation (also within a danger level).",
            "status_quo": "Same hazardous situation. <b>No significant change</b> compared to the previous day.",
            "stepping_back": "<b>Increasing tendency</b> of the hazard situation (also within a danger level).",
            "entrenchment": "<b>Almost constant, tense hazardous situation.</b> The main danger comes from (a) <b>persistent weak layer</b>(s) susceptible to interference.",
            "free_ride": "<b>Low avalanche danger</b> with generally stable snow cover. Only small avalanches are possible in extremely steep terrain. ",
            "high_alert": "Exceptional situation with <b>high or very high avalanche danger</b>. Spontaneous avalanches of <b>very large or extreme magnitude</b> are possible",
            "spring_diurnal": "Wet snow avalanches during the course of the day, which are subject to a <b>clear diurnal cycle</b>, are decisive for the risk assessment."
          }
        },
        "communication": {
          "title": "Communication:",
          "text": {
            "assessment": "Avalanche situation must be checked locally. Restraint is recommended.",
            "stepping_out": "Gradual decrease in danger. However, caution is still required and the improving situation should be assessed locally before entering riskier terrain.  ",
            "status_quo": "Hazardous situation is unchanged. Feedback from observers helps to better outline the hazardous situation. More favourable terrain should be communicated with emphasis. ",
            "stepping_back": "Clear communication of the increase in avalanche danger - even within a danger level. Information on the causes supports comprehensibility in communication. ",
            "entrenchment": "Caution and restraint are required. Situation hardly changes. If possible, provide as precise information as possible on distribution and possibility to trigger. Increased communication of observations that reveal the situation and trigger restraint.",
            "free_ride": "Little text. Emphasise the generally favourable overall situation and potentially isolated hazard areas",
            "high_alert": "Communication across all channels. The focus is no longer on winter sports. Danger even in areas that are not usually at risk of avalanches.",
            "spring_diurnal": "Favourable situation in the morning, increasing during the day. Assessment should be made locally by observing the softening of the melt-freeze crust and the increasing soaking of the snow cover."
          }
        },
        "examples": {
          "title": "Examples:",
          "text": {
            "assessment": "Start of the season.",
            "stepping_out": "Cooling leads to a decrease in the risk of wet snow avalanches after a warm and humid weather phase.",
            "status_quo": "Consistent wind-slab problem with cold temperatures, loose snow and wind.",
            "stepping_back": "Abundant fresh snowfall leads to an increase in the danger situation.",
            "entrenchment": "Critical persistent weak layers problem with potential for dangerously large avalanches. The situation is only stabilising very slowly and requires a great deal of restraint.",
            "free_ride": "Low avalanche danger, no persistent weak layers, all terrain has already been groomed.",
            "high_alert": "Danger level 4 or higher due to large amounts of fresh snow and/or problematic, persistent weak layers. ",
            "spring_diurnal": "Spring situation with dry high-pressure weather and moderate temperatures with otherwise mostly stable old snow cover."
          }
        },
        "strategicMindset": {
          "assessment": "High Uncertainty",
          "stepping_out": "Improving",
          "status_quo": "Status Quo",
          "stepping_back": "Deteriorating",
          "entrenchment": "Persistent Unstable",
          "free_ride": "Favorable",
          "high_alert": "High Alert",
          "spring_diurnal": "Spring Diurnal"
        }
      },
      "decisionTree" : {
        "filepath": "./assets/img/decision_tree-en.svg",
        "feedbackURL": "https://admin.avalanche.report/umfrage-entscheidungsbaum/en/",
        "decisionTree" : "Arbre de decisions",
        "feedback" : "Comentaris",
        "save": "Sauvar",
        "discard": "Escartar"
      }
    },
    "table": {
      "title": {
        "date": "Data",
        "status": {
          "AT-02": "Carinthia",
          "AT-03": "Baixa Àustria",
          "AT-04": "Alta Àustria",
          "AT-05": "Salzburg",
          "AT-06": "Styria",
          "AT-07": "Tiròl",
          "AT-08": "Vorarlberg",
          "CH" : "Soïssa",
          "DE-BY" : "Bavaria",
          "GEOSPHERE": "GEOSPHERE",
          "IT-32-TN": "Trentin",
          "IT-32-BZ": "Sud-Tiròl",
          "ES-CT-L": "Aran"
        }
      },
      "status": {
        "missing": "absent",
        "draft": "borrolhon",
        "submitted": "enviat",
        "published": "publicat",
        "updated": "actualizat",
        "resubmitted": "re-enviat",
        "republished": "re-publicat"
      },
      "publishBulletinsDialog": {
        "message": "Vòs publicar eth bulletin?",
        "missingDangerRating": "Grad de perilh non establit",
        "missingRegion": "Regions sense grad de perilh",
        "duplicateRegion": "Regions avalorades diuèrsi viatges",
        "missingAvActivityHighlights": "Manque eth titular dera situacion de lauegi",
        "missingAvActivityComment": "Manque era descripcion deth perilh de lauegi",
        "missingSnowpackStructureHighlights": "Manque eth titular dera estructura deth celh de nhèu",
        "missingSnowpackStructureComment": "Manque era descripcion deth celh de nhèu",
        "pendingSuggestions": "Suggeriments pendenti",
        "incompleteTranslation": "Revirada incomplèta",
        "accept": "Òc",
        "reject": "Non"
      },
      "publicationStatusDialog": {
        "headline": "Estat dera publicacion",
        "publishedBy": "Publicat per:",
        "publishedAt": "Publicat a les:",
        "validFor": "Valid per:",
        "title": {
          "prod": "Causes serioses",
          "test": "Test system",
          "task": "Prètzhèt",
          "language": {
            "all": "TOTI",
            "de": "DE",
            "it": "IT",
            "en": "EN"
          }
        },

        "caaml": {
          "label": "Fichèrs CAAML",
          "tooltip": "Crear fichèrs CAAML",
          "success": "{{prefix}}Es fichèrs CAAML s'an creat corrèctaments!",
          "error": "{{prefix}}Es fichèrs CAAML non s'an pogut crear!"
        },
        "map": {
          "label": "Mapes",
          "tooltip": "Crear Mapes",
          "success": "{{prefix}}Mapes creadi corrèctaments!",
          "error": "{{prefix}}Non s'an pogut crear es mapes!"
        },
        "pdf": {
          "label": "PDFs",
          "tooltip": "Crear PDFs",
          "success": "{{prefix}}Els PDF s'han creat correctament!",
          "error": "{{prefix}}No s'han pogut crear els PDF!"
        },
        "html": {
          "label": "HTMLs simples",
          "tooltip": "Creatz fichèrs HTML simples",
          "success": "{{prefix}}S'an creat fichèrs HTML simples corrèctaments!",
          "error": "{{prefix}}Non s'an pogut crear fichèrs HTML simples!"
        },
        "email": {
          "label": "Emails",
          "tooltip": "Enviar Emails",
          "success": "{{prefix}}Corrèus electronics enviadi corrèctaments!",
          "error": "{{prefix}}Non s'an pogut enviar es corrèus electronics!"
        },
        "telegram": {
          "label": "Messatges de Telegram",
          "tooltip": "Enviar messatges de Telegram",
          "success": "{{prefix}}Messatges de Telegram enviadi corrèctaments!",
          "error": "{{prefix}}Non s'an pogut enviar es messatges de Telegram!"
        },
        "push": {
          "label": "Notificacions Push",
          "tooltip": "Enviar notificacions Push",
          "success": "{{prefix}}Notificacions push enviades correctament!",
          "error": "{{prefix}}Non s'an pogut enviar es notificacions push!"
        },
        "accept": "d'acòrd"
      },
      "mediaFileDialog": {
        "headline": "Fichèr multimèdia",
        "important": "IMPORTANT",
        "missingFile": "Non s'a pas seleccionat cap fichèr!",
        "uploadError": "Era carga a mancat!",
        "accept": "Enviar",
        "reject": "Anullar"
      },
      "publishBulletinsErrorDialog": {
        "message": "S'a produsit un error en publicar bulletins!",
        "accept": "d'acòrd"
      },
      "submitBulletinsDialog": {
        "message": "Vòs enviar eth bulletin?",
        "missingDangerRating": "Grad de perilh non establit",
        "missingRegion": "Regions sense grad de perilh",
        "missingAvActivityHighlights": "Manque eth titular dera situacion de lauegi",
        "missingAvActivityComment": "Manque era descripcion deth perilh de lauegi",
        "missingSnowpackStructureHighlights": "Manque eth titular dera estructura deth celh de nhèu",
        "missingSnowpackStructureComment": "Manque era descripcion deth celh de nhèu",
        "pendingSuggestions": "Suggeriments pendenti",
        "accept": "Òc",
        "reject": "Non"
      },
      "submitBulletinsErrorDialog": {
        "message": "S'ha produït un error en enviar butlletins!",
        "accept": "d'acòrd"
      },
      "submitBulletinsDuplicateRegionDialog": {
        "message": "Regions avalorades diuèrsi còps! Non se pòt enviar eth bulletin.",
        "accept": "d'acòrd"
      },
      "checkBulletinsErrorDialog": {
        "message": "S'a produsit un error en verificar es bulletins!",
        "accept": "d'acòrd"
      },
      "previewErrorDialog": {
        "message": "Non s'a pogut cargar era vista prèvia!",
        "accept": "D'acòrd"
      },
      "checkBulletinsDialog": {
        "message": "Verificar eth bulletin",
        "missingDangerRating": "Grad de perilh non establit",
        "missingRegion": "Regions sense grad de perilh",
        "missingAvActivityHighlights": "Manque eth titular dera situacion de lauegi",
        "missingAvActivityComment": "Manque era descripcion deth perilh de lauegi",
        "missingSnowpackStructureHighlights": "Manque eth titular dera estructura deth celh de nhèu",
        "missingSnowpackStructureComment": "Manque era descripcion deth celh de nhèu",
        "pendingSuggestions": "Suggeriments pendenti",
        "incompleteTranslation": "Revirada incomplèta",
        "ok": "(tot corrècte)",
        "accept": "D'acòrd"
      },
      "publishAllDialog": {
        "message": "Vòs publicar totes es regions?",
        "accept": "Òc",
        "reject": "Non"
      }
    },
    "caaml": {
      "title": "CAAML",
      "caamlNotLoadedDialog": {
        "message": "Non s'a pogut cargar eth CAAML.",
        "accept": "D'acòrd"
      },
      "noCaamlDialog": {
        "message": "Non i a bulletin publicat disponible!",
        "accept": "D'acòrd"
      }
    },
    "json": {
      "title": "JSON",
      "jsonNotLoadedDialog": {
        "message": "Non s'a pogut cargar eth JSON!",
        "accept": "D'acòrd"
      },
      "noJsonDialog": {
        "message": "Non i a bulletin publicat disponible!",
        "accept": "D'acòrd"
      }
    }
  },
  "training": {
    "training": "Training",
    "timestamp": "Training timestamp"
  },
  "admin": {
    "loadingStatistics": "Creant eth CSV ...",
    "server-configuration": {
      "title": "Servidor",
      "button": {
        "create": "Crear servidor",
        "save": "Sauvar"
      },
      "table": {
        "name": "Nòm",
        "username": "Nòm d'usatgèr",
        "password": "Password",
        "publishAt5PM": "Publicar as 5 PM",
        "publishAt8AM": "Publicar as 8 AM",
        "pdfDirectory": "Directòri entà PDF",
        "htmlDirectory": "Directòri entà HTML",
        "serverImagesUrl": "Rota as imatges deth servidor",
        "mapsPath": "Rota as mapes",
        "mediaPath": "Rota des fichèrs multimèdia",
        "mapProductionUrl": "URL entara produccion de mapes",
        "apiUrl": "API URL"
      },
      "success": "Configuracion sauvada corrèctaments",
      "error": "Non s'a pogut sauvar era configuracion"
    },
    "region-configuration": {
      "title": "Region",
      "button": {
        "create": "Crear region",
        "save": "Sauvar"
      },
      "table": {
        "general": {
          "title": "General",
          "id": "ID",
          "microRegions": "Nombre de micro-regions",
          "subRegions": "Sub-regions",
          "superRegions": "Super-regions",
          "neighborRegions": "Regions vesines"
        },
        "publication": {
          "title": "Publication",
          "publishBulletins": "Publicar era prediccion de lauegi",
          "publishBlogs": "Publicar entrades de blòg",
          "createCaamlV5": "Crear CAAML v5",
          "createCaamlV6": "Crear CAAML v6",
          "createJson": "Crear JSON",
          "createMaps": "Crear mapes",
          "createPdf": "Crear PDF",
          "sendEmails": "Corrèus electronics enviadi",
          "createSimpleHtml": "Crear HTML simple",
          "sendTelegramMessages": "Enviar messatges de Telegram",
          "sendPushNotifications": "Enviar notificacions push",
          "pdfColor": "PDF color",
          "emailColor": "Email color",
          "pdfMapYAmPm": "Y entà mapa PDF (am/pm)",
          "pdfMapYFd": "Y entà mapa PDF (fd)",
          "pdfMapWidthAmPm": "Amplada de mapa entà PDF (am/pm)",
          "pdfMapWidthFd": "Amplada de map entà PDF (fd)",
          "pdfMapHeight": "Nautada de mapa entà PDF",
          "pdfFooterLogo": "Logotip entath pè de pagina de PDF",
          "pdfFooterLogoColorPath": "Logotip entath pè de pagina de PDF (color)",
          "pdfFooterLogoBwPath": "Logotip entath pè de pagina de PDF (bw)",
          "mapXmax": "X deth mapa màxim",
          "mapXmin": "X deth mapa minim",
          "mapYmax": "Y deth mapa màxim",
          "mapYmin": "Y deth mapa mínim",
          "simpleHtmlTemplateName": "Plantilha HTML simpla",
          "geoDataDirectory": "Directòri de geodonades",
          "mapLogoColorPath": "Logotip entath mapa (color)",
          "mapLogoBwPath": "Logotip entath mapa (bw)",
          "mapLogoPosition": "Posicion deth logotip entath mapa",
          "mapCenterLat": "Centrar posicion deth mapa (latitud)",
          "mapCenterLng": "Centrar posicion deth mapa (longitud)",
          "imageColorbarColorPath": "Barra de colors (color)",
          "imageColorbarBwPath": "Barra de colors (bw)"
        },
        "components": {
          "title": "Components",
          "enableDangerSources": "Enable danger sources",
          "enableObservations": "Enable observations",
          "enableModelling": "Enable modelling",
          "enableWeatherbox": "Enable weather box"
        },
        "configuration": {
          "title": "Configuration",
          "showMatrix": "Mostrar era mairitz",
          "enableMediaFile": "Activar eth fichèr multimèdia",
          "enableStrategicMindset": "Enable strategic mindset",
          "enableStressLevel": "Enable stress level",
          "enableAvalancheProblemCornices": "Abilitar eth problèma de lauegi CORNISES",
          "enableAvalancheProblemNoDistinctAvalancheProblem": "Abilitar eth problèma de lauegi CAP PROBLÈMA DE LAUEGI EVIDENT"
        }
      },
      "success": "Configuracion sauvada corrèctaments",
      "error": "Non s'a pogut sauvar era configuracion"
    },
    "users": {
      "title": "Usuaris",
      "button": {
        "create": " Crear usatgèr"
      },
      "tooltip": {
        "edit": "Editar usuari",
        "changePassword": "Cambiar contrasenya",
        "delete": "Eliminar usuari"
      },
      "table": {
        "name": "Nòm",
        "email": "Corrèu electronic",
        "organization": "Organizacion",
        "regions": "Regions",
        "roles": "Ròtles"
      },
      "deleteUserModalDialog": {
        "message": "Voletz eliminar er usatgèr?",
        "accept": "Òc",
        "reject": "Non"
      },
      "deleteUser": {
        "success": "Usatgèr eliminat",
        "error": "Er usatgèr non a pogut èster eliminat"
      },
      "createUser": {
        "success": "Usatgèr creat",
        "error": "Non s'a pogut crear er usatgèr"
      },
      "updateUser": {
        "success": "Usatgèr actualizat",
        "error": "Er usatgèr non s'a pogut actualizar"
      }
    },
    "createUser": {
      "table": {
        "image": "Image",
        "name": "Nòm",
        "email": "Corrèu electronic",
        "organization": "Organizacion",
        "password": "Password",
        "password2": "Repetir password",
        "roles": "Ròtles",
        "regions": "Regions"
      },
      "error": {
        "name": {
          "required": "Introduir un nòm"
        },
        "email": {
          "required": "Introduir ua adreça de corrèu electronic",
          "invalid": "Era adreça electronica non ei valida"
        },
        "password": {
          "required": "Introduir ua contrasenha",
          "length": "Era contrasenha a d'auer aumens 8 caracters",
          "mismatch": "Es contrasenhes non coïncidissen"
        }
      },
      "button": {
        "create": "Crear usuari",
        "update": "Actualizar usatgèr",
        "cancel": "Anullar"
      }
    },
    "observations": {
      "table": "Taula",
      "map": "Mapa",
      "gallery": "Gallery",
      "filters": "Filtres",
      "title": "Observacions",
      "newObservation": "New observation",
      "exportObservations": "Export observations",
      "toggleFilterBar": "Show/hide filter bar",
      "button": {
        "submit": "Descargar"
      },
      "label": {
        "dateRange": "Periòde",
        "observer": "Observador"
      },
      "selectRegion": {
        "placeholder": "Region",
        "selectionLabel": "{0} region(s) selected"
      },
      "selectSources": {
        "placeholder": "Hònts",
        "selectionLabel": "{0} source(s) selected"
      },
      "charts": {
        "charts": "Grafiques",
        "nan": "Sense especificacion",
        "classify": "Classificar",
        "label": "Etiqueta",
        "invert": "Invertir",
        "reset": "Restablir",
        "aspect": {
          "caption": "Orientacion"
        },
        "elevation": {
          "caption": "Nautada"
        },
        "stability": {
          "caption": "Estabilitat"
        },
        "observationType": {
          "caption": "Tipe d'observacions"
        },
        "importantObservation": {
          "caption": "Important"
        },
        "avalancheProblem": {
          "caption": "Problèma de lauegi"
        },
        "dangerPattern": {
          "caption": "Patron de perilh"
        },
        "days": {
          "caption": "Dia"
        }
      }
    },
    "blog": {
      "title": "Blòg",
      "language": {
        "title": "Lengua",
        "de": "DE",
        "it": "IT",
        "en": "EN"
      },
      "all": {
        "tooltip": "Enviar corrèus electronics, Telegram e Push",
        "success": "{{prefix}}Corrèus electronics, Telegram e Push enviadi corrèctaments!",
        "error": "{{prefix}}Non s'an pogut enviar es corrèus electronics, Telegram e Push!"
      },
      "email": {
        "tooltip": "Enviar corrèus electronics",
        "success": "{{prefix}}Corrèus electronics enviadi corrèctaments!",
        "error": "{{prefix}}Non s'an pogut enviar es corrèus electronics!"
      },
      "telegram": {
        "tooltip": "Enviat per canau de Telegram",
        "success": "{{prefix}}Telegram enviat corrèctaments!",
        "error": "{{prefix}}Non s'a pogut enviar eth Telegram!"
      },
      "push": {
        "tooltip": "Enviar per Notificacions Push",
        "success": "{{prefix}}Push enviat corrèctaments!",
        "error": "{{prefix}}Non s'a pogut enviar eth Push!"
      }
    }
  },
  "settings": {
    "title": "Configuracion",
    "changePassword": {
      "title": "Cambiar contrasenya",
      "oldPassword": {
        "label": "Contrasenya actuau",
        "placeholder": "Contrasenya actuau"
      },
      "newPassword1": {
        "label": "Naua contrasenya",
        "placeholder": "Naua contrasenya"
      },
      "newPassword2": {
        "label": "Contrasenha naua (repetir)",
        "placeholder": "Naua contrasenya"
      },
      "submit": "Cambiar contrasenya",
      "passwordChanged": "Contrasenya cambiada corrèctaments",
      "passwordChangeError": "Non s'a pogut cambiar era contrasenya",
      "passwordIncorrect": "Era contrasenha actuau ei incorrècta",
      "error": {
        "oldPassword": {
          "required": "Se te platz, entre era tua contrasenya actuau"
        },
        "newPassword": {
          "required": "Se te platz, entre ua naua contrasenya",
          "mismatch": "Es contrasenhes non coïncidissen"
        }
      }
    },
    "display": {
      "title": "Visualizacion",
      "table": {
        "showCaaml": "Mostrar CAAML",
        "showJson": "Mostrar JSON"
      }
    }
  },
  "statistics": {
    "title": "Estadístiques",
    "button": {
      "submit": "Descargar"
    },
    "label": {
      "dateRange": "Periòde",
      "extended": "Estenuda",
      "duplicates": "Duplicats"
    }
  },

  "dangerRating": {
    "noRating": "Sense grad de perilh",
    "low": "fèble",
    "moderate": "moderat",
    "considerable": "mercat",
    "high": "fòrt",
    "veryHigh": "molt fòrt"
  },
  "avalancheProblem": {
    "newSnow": "Nhèu recenta",
    "new_snow": "Nhèu recenta",
    "windSlab": "Plaques de vent",
    "wind_slab": "Plaques de vent",
    "persistentWeakLayers": "Coches fèbles persistentes",
    "persistent_weak_layers": "Coches febles persistentes",
    "wetSnow": "Nhèu umida",
    "wet_snow": "Nhèu umida",
    "glidingSnow": "Esguitlaments basaus",
    "gliding_snow": "Esguitlaments basaus",
    "cornices": "Cornises",
    "noDistinctAvalancheProblem": "Cap problèma de lauegi evident",
    "no_distinct_problem": "Cap problèma de lauegi evident",
    "favourableSituation": "Situacion favorabla",
    "favourable_situation": "Situacion favorabla"
  },
  "dangerPattern": {
    "dp1": "pp.1:  capa fèbla persistenta e prigonda",
    "dp2": "pp.2: litsades basaus",
    "dp3": "pp.3: ploja",
    "dp4": "pp.4: heired dempús de caud / caud dempús de heired",
    "dp5": "pp.5: nheuades dempús d’un long periòde de heired",
    "dp6": "pp.6: nhèu heireda, sense coesion e vent",
    "dp7": "pp.7: zònes praubes de nhèu as environs rics en nhèu",
    "dp8": "pp.8: geladiua de superfícia enterrada",
    "dp9": "pp.9: graupel enterrat",
    "dp10": "pp.10: scenari de primauera"
  },
  "strategicMindset": {
    "title": "Strategic Mindset",
    "assessment": "High Uncertainty",
    "stepping_out": "Improving",
    "status_quo": "Status Quo",
    "stepping_back": "Deteriorating",
    "entrenchment": "Persistent Unstable",
    "free_ride": "Favorable",
    "high_alert": "High Alert",
    "spring_diurnal": "Spring Diurnal"
  },
  "observationType": {
    "TimeSeries": "Series temporaus",
    "Incident": "Incident",
    "Profile": "Perfil de nhèu",
    "Closure": "Barrament",
    "Blasting": "Voladura",
    "Avalanche": "Lauet",
    "Evaluation": "Avaloracion",
    "SimpleObservation": "Observacion simple",
    "Webcam": "Webcam"
  },
  "importantObservation": {
    "SnowLine": "Limit de nhèu",
    "SurfaceHoar": "Gibra de superfícia",
    "Graupel": "Nhèu redona",
    "StabilityTest": "Test d'estabilitat",
    "IceFormation": "Formacion de gèu",
    "VeryLightNewSnow": "Nhèu recenta molt leugèra"
  },
  "aspect": {
    "N": "N",
    "NE": "NE",
    "E": "E",
    "SE": "SE",
    "S": "S",
    "SW": "SW",
    "W": "W",
    "NW": "NW"
  },
  "role": {
    "SUPERADMIN": "Super Administrator",
    "ADMIN": "Administrador",
    "FORECASTER": "Predictor",
    "FOREMAN": "Cap",
    "OBSERVER": "Observador"
  },
  "tendency": {
    "decreasing": "Perilh de lauegi diminuís",
    "steady": "Perilh de lauegi seguís parièr",
    "increasing": "El perill d’allaus aumente"
  },
  "snowpackStability": {
    "unknown": "desconeishut",
    "good": "bona",
    "fair": "regular",
    "poor": "praube",
    "very_poor": "molt praube"
  },
  "frequency": {
    "none": "(quasi) cap",
    "few": "ues pòques",
    "some": "beres ues",
    "many": "moltes"
  },
  "avalancheSize": {
    "small": "petit",
    "medium": "mejan",
    "large": "gran",
    "very_large": "molt gran",
    "extreme": "extremadament gran"
  },
  "complexity": {
    "easy": "Facil",
    "challenging": "Exigent",
    "complex": "Complèxe"
  },
  "glidingSnowActivity": {
    "low": "fèble",
    "medium": "mejan",
    "high": "fòrt"
  },
  "wetness": {
    "dry": "dry",
    "moist": "moist",
    "wet": "wet"
  },
  "characteristic": {
    "low": "fèble",
    "medium": "mejan",
    "high": "fòrt",
    "very_high": "molt fòrt"
  },
  "thickness": {
    "thin": "thin",
    "thick": "thick"
  },
  "snowpackPosition": {
    "upper": "upper",
    "middle": "middle",
    "lower": "lower",
    "ground": "ground"
  },
  "creationProcess": {
    "radiation_recrystallization": "radiacion",
    "diurnal_recrystallization": "diurnal",
    "melt_layer_recrystallization": "melt layer"
  },
  "recognizability": {
    "very_easy": "very easy",
    "easy": "easy",
    "hard": "hard",
    "very_hard": "very hard"
  },
  "distribution": {
    "isolated": "isolated",
    "specific": "specific",
    "widespread": "widespread"
  },
  "probability": {
    "likely": "likely",
    "possible": "possible",
    "unlikely": "unlikely"
  },
  "detailedDaytime": {
    "evening": "evening",
    "first_night_half": "first night half",
    "second_night_half": "second night half",
    "morning": "morning",
    "forenoon": "forenoon",
    "afternoon": "afternoon"
  },
  "slopeGradient": {
    "moderately_steep": "moderately steep",
    "steep": "steep",
    "very_steep": "very steep",
    "extremely_steep": "extremely steep"
  },
  "handHardness": {
    "fist": "F",
    "four_fingers": "4F",
    "one_finger": "1F",
    "pencil": "P",
    "knife": "K",
    "ice": "I"
  },
  "hardnessTendency": {
    "decreasing": "decreasing with height",
    "steady": "uniform",
    "increasing": "increasing with height"
  },
  "terrainType": {
    "gullies": "gullies",
    "bowls": "bowls",
    "pass_areas": "pass areas",
    "ridge_lines": "ridge lines"
  },
  "dangerSign": {
    "shooting_cracks": "shooting cracks",
    "whumpfing": "whumpfing"
  },
  "changeRegionDialog": {
    "message": "Vòs cambiar de region?",
    "accept": "Òc",
    "reject": "Non"
  },
  "zamg": {
    "table": "Taula",
    "map": "Mapa",
    "region": "Region",
    "newSnow": "Nhèu recenta"
  },
  "snowpack": {
    "plotType": {
      "LWC_stratigraphy": "nhèu umida: contengut d'aigua liquida",
      "wet_snow_instability": "nhèu umida: inestabilitat dera nhèu umida",
      "Sk38_stratigraphy": "coches fèbles persistentes: indèx estabilitat SK38",
      "stratigraphy": "coches fèbles persistentes: estratigrafia dera nhèu"
    },
    "aspect": {
      "flat": "plan",
      "north": "nòrd",
      "south": "sud"
    },
    "meteo": {
      "new_snow_plot_3day": "nhèu recènta (3d)",
      "new_snow_plot_7day": "nhèu recènta (7d)",
      "new_snow_plot_1month": "nhèu recènta (31d)",
      "new_snow_plot_season": "nhèu recènta (sason)",
      "new_snow_plot_forecast": "nhèu recènta (prediccion)",
      "wet_snow_plot_3day": "nhèu umida (3d)",
      "wet_snow_plot_7day": "nhèu umida (7d)",
      "wet_snow_plot_1month": "nhèu umida (31d)",
      "wet_snow_plot_season": "nhèu umida (sason)",
      "wet_snow_plot_forecast": "nhèu umida (prediccion)",
      "HS_table_24h": "taula nautada de nhèu (1d)",
      "HS_table_72h": "taula nautada de nhèu (3d)",
      "HS_table_season": "taula nautada de nhèu (sason)",
      "HS_table_forecast": "taula nautada de nhèu (prevision)",
      "TA_table_24h": "taula temperatura (1d)",
      "TA_table_72h": "taula temperatura (3d)",
      "TA_table_season": "taula temperatura (sason)"
    }
  },
  "grainShape": {
    "PP": {
      "code": "PP",
      "class": "Precipitation Particles"
    },
    "PPgp": {
      "code": "PPgp",
      "class": "Nhèu redona"
    },
    "DF": {
      "code": "DF",
      "class": "Decomposing and Fragmented precipitation particles"
    },
    "RG": {
      "code": "RG",
      "class": "Rounded Grains"
    },
    "FC": {
      "code": "FC",
      "class": "Faceted Crystals"
    },
    "DH": {
      "code": "DH",
      "class": "Depth Hoar"
    },
    "SH": {
      "code": "SH",
      "class": "Surface Hoar"
    },
    "MF": {
      "code": "MF",
      "class": "Melt Forms"
    },
    "MFcr": {
      "code": "MFcr",
      "class": "Melt-freeze crust"
    },
    "IF": {
      "code": "IF",
      "class": "Ice Formations"
    }
  },
  "button": {
    "yes": "yes",
    "no": "no"
  },
  "observations": {
    "aspect": "Orientacion",
    "authorName": "Nom de l'autor",
    "avalancheLength": "Longitud de lauet",
    "avalancheProblem": "Problèma de lauegi",
    "avalancheSize": "Mida de lauet",
    "avalancheType": "Tipe de lauet",
    "avalancheWidth": "Amplada de lauet",
    "search": "Cercar",
    "count": "# observacions:",
    "layers": {
      "observers": "Observers",
      "weatherStations": "Weather stations",
      "webcams": "Webcams",
      "observations": "Observacions"
    },
    "weatherStations": {
      "tooltips": {
        "globalRadiation": "Global radiation",
        "snowHeight": "Snow height",
        "snowDifference24h": "Snow difference 24h",
        "snowDifference48h": "Snow difference 48h",
        "snowDifference72h": "Snow difference 72h",
        "airTemperature": "Air temperature",
        "airTemperatureMax": "Air temperature Max",
        "airTemperatureMin": "Air temperature Min",
        "surfaceTemperature": "Surface temperature",
        "surfaceHoar": "Potential surface hoar formation",
        "surfaceHoarCalc": "Potential surface hoar formation (Lehning et. al.)",
        "dewPoint": "Dew point temperature",
        "relativeHumidity": "Relative humidity",
        "windDirection": "Wind direction",
        "windSpeed": "Wind speed",
        "windGust": "Wind gust"
      }
    },
    "button": {
      "add": "íger observacion",
      "save": "Sauvar observacion",
      "delete": "Eliminar observacion",
      "deleteConfirm": "Eliminar observacion?",
      "discard": "Escartar"
    },
    "comment": "Comentari",
    "content": "Contengut",
    "dangerRating": "Grad de perilh",
    "elevation": "Nautada",
    "eventDate": "Data d'eveniment",
    "eventType": "Tipe d'eveniment",
    "eventTypes": {
      "IMPORTANT": "Eveniment important",
      "NEIGHBOR_REGION": "Region vesia",
      "NORMAL": "Eveniment normau",
      "PERSON_DEAD": "Eveniment damb persones implicades (mòrtes)",
      "PERSON_INJURED": "Eveniment damb persones implicades (herides)",
      "PERSON_NO": "Eveniment sense persones implicades",
      "PERSON_UNINJURED": "Eveniment damb persones implicades (il·leses)",
      "PERSON_UNKNOWN": "Eveniment (desconeishut)",
      "TRAFFIC": "Transit"
    },
    "fractureDepth": "Prigondor de cicatritz",
    "hasCoordinates": "A coordenades",
    "incline": "Inclinacion",
    "latitude": "Latitud",
    "locationName": "Localitat",
    "longitude": "Longitud",
    "reportDate": "Data der infòrme",
    "showTable": "Taula",
    "withoutCoordinates": "Observacions sense coordenades:",
    "sidebar": {
      "filter": {
        "title": "Filtrar"
      },
      "legend": {
        "title": "Legenda"
      },
      "sources": {
        "title": "Hònts de donades"
      },
      "views": {
        "title": "Vistes"
      }
    }
  },
  "units": {
    "cm": "cm",
    "mm": "mm",
    "m": "m"
  },
  "qfa": {
    "day": "Dia",
    "time": "Òra",
    "back": "Endarrere",
    "parameters": {
      "DUST": "povàs saharià",
      "N": "cobertura de bromes totau en /8",
      "Nh": "cobertura de bromes nautes en /8",
      "Nm": "cobertura de bromes mejanes en /8",
      "Nl": "cobertura de bromes baishes en /8",
      "N-CU": "cobertura de bromes cumulus en /8",
      "AUSL.T": "temperatura desencadenant",
      "SHW-IX": "Índex Showalter",
      "WX -CUF": "temps significatiu: NIL (cap), RASH (ploja), TS (tempèsta)",
      "RR": "precipitacion totau [mm]",
      "RR-str": "precipitacion estratiforme",
      "CONV": "precipitacion convectiua [mm]",
      "SN": "precipitacion en forma de nhèu [mm]",
      "SN--RA": "límit dera nhèu [m]",
      "QAO ": "{{value}} hPa eq. vent mejan, direccion e velocitat, [°] e [m/s]",
      "QAN": "vent mejan en tèrra, direccion e velocitat, [°] e [m/s]",
      "QANmax": "vet màx. (bohades) en tèrra [m/s]",
      "T ": "{{value}} hPa eq. temperatura [°C]",
      "T m": "temperatura a {{value}}m [°C]",
      "T 2m": "temperatura a 2m [°C]",
      "Tk 2m": "temperatura a 2m [°K]",
      "T GND": "temperatura en tèrra",
      "Max-ADI": "temperatura maximala de barreja [°C]",
      "Min Max": "mín./màx. temperatura enes darrères 24ores",
      "FROST": "nautada isozèro [m]",
      "W cm": "500 hPa eq. vent verticau [cm/s]",
      "W --": "700 hPa eq. vent verticau [cm/s]",
      "W s": "{{value}} hPa eq. vent verticau [cm/s]",
      "H ": "{{value}} hPa nautada geopotenciau [m]",
      "RF ": "{{value}} hPa eq. umiditat relatiua [%]"
    }
  }
}
