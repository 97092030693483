{
  "daytime": {
    "earlier": "Earlier",
    "later": "Later"
  },
  "login": {
    "title": "Login",
    "text": "Sign In to your account",
    "button": "Login",
    "stressLevel": "Stress Level",
    "errorDialog": {
      "message": "Incorrect username or password!",
      "accept": "Ok"
    },
    "placeholder": {
      "username": "Please enter your username",
      "password": "Please enter your password"
    }
  },
  "sidebar": {
    "bulletins": "Avalanche.report",
    "dangerSources": "Danger Sources",
    "observations": "Observations",
    "qfa": "QFA",
    "modelling": "Modelling",
    "modellingForecast": "Forecast",
    "modellingZamg": "ZAMG multi model",
    "modellingZamgECMWF": "ZAMG ECMWF-EPS",
    "modellingZamgCLAEF": "ZAMG CLAEF-EPS",
    "modellingZamgMeteogram": "GeoSphere meteogram",
    "modellingSnowpack": "SNOWPACK model",
    "modellingSnowpackMeteo": "SNOWPACK modelled meteo",
    "weatherbox": "Weatherbox",
    "awsome": "AWSOME",
    "snowpack": "SNOWPACK",
    "geosphere": "GeoSphere"
  },
  "menu": {
    "logout": "Logout",
    "language": "Language",
    "en": "English",
    "de": "German",
    "fr": "French",
    "it": "Italian",
    "es": "Spanish",
    "ca": "Catalan",
    "oc": "Aranese",
    "fullscreen": "Fullscreen",
    "settings": "Settings",
    "statistics": "Statistics",
    "admin": "Admin",
    "region": "Region",
    "education": "Education"
  },
  "matrix": {
    "label": {
      "snowpackStability": "Snowpack Stability",
      "frequency": "Frequency",
      "avalancheSize": "Avalanche Size",
      "dangerRating": "Danger Rating",
      "override": "(override danger rating)",
      "matrix": "Matrix",
      "referToFairStability": "Refer to fair stability",
      "referToPoorStability": "Refer to poor stability",
      "dangerLevel1": "Danger level 1 (Low)"
    }
  },
  "dangerSources": {
    "status": {
      "missing": "missing",
      "available": "available",
      "forecast": "forecast",
      "analysis": "analysis",
      "loading": "Loading danger sources ...",
      "saveError": "Danger source could not be saved!",
      "loadDangerSourcesError": "Danger sources could not be loaded!",
      "loadVariantsError": "Danger source variants could not be loaded!"
    },
    "variantStatus": {
      "active": "active",
      "dormant": "dormant",
      "inactive": "inactive"
    },
    "tooltip": {
      "read": "Read danger sources",
      "edit": "Edit danger sources"
    },
    "table": {
      "loadMore": "Load {{count}} more days",
      "title": {
        "forecast": "Forecast",
        "analysis": "Analysis"
      }
    },
    "create": {
      "variantStatus": "Status",
      "variantEditable": "This variant can be edited.",
      "variantNotEditable": "This variant can not be edited.",
      "noVariantsForSelectedDay": "No variants defined for this date.",
      "tooltip": {
        "editDangerSource": "Edit danger source",
        "loadVariants": "Load variants from the day before",
        "edit": "Edit micro regions",
        "copy": "Copy variant",
        "delete": "Delete variant",
        "compare": "Compare variant",
        "forecast": "Compare with forecast"
      },
      "createDangerSource": "Create danger source",
      "createVariant": "Create new variant",
      "noDangerSources": "No danger sources",
      "title": {
        "avalanche": "Avalanche",
        "matrix": "Matrix",
        "characteristics": "Characteristics"
      },
      "label": {
        "glidingSnowActivity": "Gliding snow activity",
        "snowHeight": "Snow height",
        "zeroDegreeIsotherm": "0°C isotherm",
        "looseSnowMoisture": "Loose snow moisture",
        "looseSnowGrainShape": "Loose snow grain shape",
        "slabGrainShape": "Slab grain shape",
        "slabThickness": "Slab thickness",
        "slabHandHardness": "Slab hand hardness",
        "slabHardnessProfile": "Slab hardness",
        "slabEnergyTransferPotential": "Slab energy transfer potential",
        "slabDistribution": "Slab distribution",
        "weakLayerGrainShape": "Weak layer grain shape",
        "weakLayerGrainSize": "Weak layer grain size",
        "weakLayerPersistent": "Weak layer persistent",
        "weakLayerThickness": "Weak layer thickness",
        "weakLayerStrength": "Weak layer strength",
        "weakLayerWet": "Weak layer wet",
        "weakLayerCrustAbove": "Weak layer crust above",
        "weakLayerCrustBelow": "Weak layer crust below",
        "weakLayerPosition": "Weak layer position",
        "weakLayerCreation": "Weak layer creation",
        "weakLayerDistribution": "Weak layer distribution",
        "dangerSpotRecognizability": "Danger spot recognizability",
        "remoteTriggering": "Remote triggering",
        "hasDaytimeDependency": "Daytime dependency",
        "dangerIncreaseWithElevation": "Danger increase with elevation",
        "runoutIntoGreen": "Runout into green",
        "highestDangerAspect": "Highest danger aspect",
        "dangerPeak": "Danger peak",
        "slopeGradient": "Slope gradient",
        "naturalRelease": "Natural release",
        "terrainTypes": "Terrain types",
        "dangerSigns": "Danger signs",
        "aspects": "Aspects"
      },
      "editDangerSourceDialog": {
        "save": "Save",
        "cancel": "Cancel"
      }
    }
  },
  "avalancheType": {
    "title": "Avalanche type",
    "label": {
      "slab": "SLAB",
      "loose": "LOOSE",
      "glide": "GLIDE"
    },
    "tooltip": {
      "slab": "Slab avalanche",
      "loose": "Loose snow avalanche",
      "glide": "Glide snow avalanche"
    }
  },
  "elevation": {
    "title": "Elevation",
    "high": "Below",
    "low": "Above",
    "treeline": "Treeline"
  },
  "bulletins": {
    "title": "Avalanche.report",
    "tooltip": {
      "edit": "Edit bulletin",
      "read": "Read bulletin",
      "copy": "Copy bulletin",
      "paste": "Paste bulletin",
      "cancel": "Cancel copy",
      "update": "Edit",
      "submit": "Submit",
      "submitUpdate": "Submit update",
      "publishChange": "Publish now (no messages)",
      "publishUpdate": "Publish now",
      "caaml": "Show CAAML",
      "json": "Show JSON",
      "create": "Create bulletin",
      "preview": "Preview bulletin (PDF)",
      "check": "Check bulletin",
      "info": "Show publication status",
      "publishAll": "Publish all regions",
      "mediaFile": "Upload media file"
    },
    "status": {
      "submittedInfo": "Will be published at 5PM",
      "resubmittedInfo": "Will be published at 8AM",
      "publishedInfo" : "Has been published",
      "noPublicationInfo" : "Will NOT be published automatically",
      "loadingPreview": "Creating PDF preview ...",
      "loading": "Loading bulletin ...",
      "loadMoreBulletins": "Load {{count}} more bulletins",
      "publishing": "Publishing bulletin ...",
      "submitting": "Submitting bulletin ...",
      "saveError": "Bulletin could not be saved!",
      "loadBulletinsError": "Bulletins could not be loaded!"
    },
    "create": {
      "tooltip": {
        "edit": "Edit micro regions",
        "copy": "Copy region",
        "delete": "Delete region",
        "undo": "Undo last change in region",
        "redo": "Redo change in region",
        "createRegion": "Create region",
        "cancel": "Cancel",
        "acceptSuggestion": "Accept suggested regions",
        "rejectSuggestion": "Decline suggested regions",
        "saveChanges": "Save changes",
        "discardChanges": "Discard changes",
        "incompleteAvalancheProblem": "Incomplete avalanche problem",
        "saveBulletin": "Save bulletin",
        "discardBulletin": "Discard bulletin changes",
        "loadBulletin": "Load bulletin from the day before",
        "uploadJsonBulletin": "Upload bulletin in JSON format",
        "downloadJsonBulletin": "Download bulletin in JSON format",
        "daytimeDependency": "Daytime dependency",
        "treeline": "Treeline",
        "tendency": {
          "decreasing": "Avalanche danger decreases",
          "steady": "Avalanche danger stays the same",
          "increasing": "Avalanche danger increases",
          "danger": "Avalanche danger"
        },
        "textcat": {
          "edit": "Edit",
          "copy": "Copy",
          "paste": "Paste",
          "example": "Load example text",
          "delete": "Delete"
        },
        "moveUpAvalancheProblem": "Move avalanche problem up",
        "moveDownAvalancheProblem": "Move avalanche problem down",
        "dangerRatingDirectionUp": "Upwards",
        "dangerRatingDirectionDown": "Downwards"
      },
      "save": " Save",
      "discard": " Discard",
      "back": " Back",
      "createAggregatedRegion": " New region",
      "pasteAggregatedRegion": " Paste region",
      "pendingSuggestions": "Suggestions",
      "rejectSuggestion": "Reject",
      "acceptSuggestion": "Accept",
      "cancelCopyBulletin": " Cancel",
      "createAvalancheProblem": " New problem",
      "ownRegions": "Own regions",
      "foreignRegions": "Foreign regions",
      "externalRegions": "External regions",
      "mapSelectRegion": "Select regions on the map.",
      "statusHeader": "Status of the bulletin",
      "suggestions": "Suggestions",
      "noBulletinsForSelectedDay": "No regions defined for this date.",
      "autosave": "Saved automatically",
      "regionEditable": "This region can be edited.",
      "regionNotEditable": "This region can not be edited.",
      "regionLocked": "This region is locked.",
      "changeDangerRatingElevation": "Extend the danger level",
      "mapTop": "Map above",
      "mapLeft": "Map aside",
      "showNotes": "Show notes",
      "hideNotes": "Hide notes",
      "incompleteAvalancheProblem": "Incomplete",
      "label": {
        "importantObservations": "Important observations",
        "avalancheProblems": "Avalanche problem",
        "aspects": "Aspects",
        "highlights": "Special alert",
        "avActivityHighlights": "Headline avalanche situation",
        "avActivityComment": "Description of avalanche danger",
        "snowpackStructureComment": "Description of snowpack structure",
        "dangerPatterns": "Danger patterns",
        "tendencyComment": "Description of tendency",
        "notes": "Notes"
      },
      "copyRegion": {
        "label": "Copy warning region to another bulletin",
        "select": "Paste into ...",
        "currentBulletin": "This bulletin"
      },
      "title": {
        "avalancheProblem": "Avalanche problems",
        "dangerDescription": "Description of avalanche danger",
        "forenoon": "Forenoon",
        "afternoon": "Afternoon",
        "snowpackStructure": "Snowpack structure",
        "tendency": "Tendency",
        "showTranslations": " Show translations",
        "hideTranslations": " Hide translations"
      },
      "placeholder": {
        "notes": "Write your notes here ..."
      },
      "pmDialog": {
        "noIFrameSupport": "Your browser does not support IFrames!"
      },
      "discardDialog": {
        "message": "Do you want to discard the changes?",
        "accept": "Yes",
        "reject": "No"
      },
      "deleteAggregatedRegionDialog": {
        "message": "Do you want to delete this region?",
        "accept": "Yes",
        "reject": "No"
      },
      "noRegionDialog": {
        "message": "At least one region has to be yours.",
        "accept": "Ok"
      },
      "loadDialog": {
        "message": "Do you want to load the bulletins from the day before?",
        "accept": "Yes",
        "reject": "No"
      },
      "loadAutoSaveDialog": {
        "message": "Do you want to load the automatically saved bulletins?",
        "accept": "Yes",
        "reject": "No"
      },
      "saveErrorDialog": {
        "message": "There was an error saving the bulletins!",
        "accept": "Ok"
      },
      "changeErrorDialog": {
        "message": "There was an error changing the bulletins!",
        "accept": "Ok"
      },
      "loadingErrorDialog": {
        "message": "There was an error loading the bulletins!",
        "accept": "Ok"
      },
      "loadingJsonFileErrorDialog": {
        "message": "There was an error loading the bulletins from the JSON file!",
        "accept": "Ok"
      },
      "avalancheProblemErrorDialog": {
        "message": "Avalanche problem, aspects, snowpack stability, frequency and/or avalanche size is missing for at least one avalanche problem",
        "accept": "Ok"
      },
      "loadAvActivityCommentExampleTextDialog": {
        "message": "For which situation do you want to load an example text?",
        "newSnow": "New snow",
        "windSlab": "Wind slab",
        "persistentWeakLayers": "Persistent weak layers",
        "wetSnow": "Wet snow",
        "glidingSnow": "Gliding snow",
        "favourableSituation": "Favourable situation",
        "cancel": "Cancel"
      },
      "removeDaytimeDependencyDialog" : {
        "message": "Which avalanche problems do you want to keep?",
        "cancel": "Cancel"
      },
      "strategicMindsetDialog" : {
        "title": "Strategic Mindsets",
        "introduction": "The \"Strategic Mindsets\" are intended to express our mental attitude with regard to the avalanche situation and its development in the corresponding warning region. It should not be derived directly from the hazard assessment, but rather understood and used as an intuitive impression of the overall situation. The \"Strategic Mindsets\" are therefore primarily a communication tool. Using the mindsets and associated definitions, we forecasters can quickly and efficiently exchange information about the development of the hazard situation and find a common language for communication.",
        "description": {
          "title": "Description:",
          "text": {
            "assessment": "There is <b>great uncertainty</b> in the hazard assessment due to <b>insufficient information</b> from the field.",
            "stepping_out": "<b>Decreasing tendency</b> of the hazard situation (also within a danger level).",
            "status_quo": "Same hazardous situation. <b>No significant change</b> compared to the previous day.",
            "stepping_back": "<b>Increasing tendency</b> of the hazard situation (also within a danger level).",
            "entrenchment": "<b>Almost constant, tense hazardous situation.</b> The main danger comes from (a) <b>persistent weak layer</b>(s) susceptible to interference.",
            "free_ride": "<b>Low avalanche danger</b> with generally stable snow cover. Only small avalanches are possible in extremely steep terrain. ",
            "high_alert": "Exceptional situation with <b>high or very high avalanche danger</b>. Spontaneous avalanches of <b>very large or extreme magnitude</b> are possible",
            "spring_diurnal": "Wet snow avalanches during the course of the day, which are subject to a <b>clear diurnal cycle</b>, are decisive for the risk assessment."
          }
        },
        "communication": {
          "title": "Communication:",
          "text": {
            "assessment": "Avalanche situation must be checked locally. Restraint is recommended.",
            "stepping_out": "Gradual decrease in danger. However, caution is still required and the improving situation should be assessed locally before entering riskier terrain.  ",
            "status_quo": "Hazardous situation is unchanged. Feedback from observers helps to better outline the hazardous situation. More favourable terrain should be communicated with emphasis. ",
            "stepping_back": "Clear communication of the increase in avalanche danger - even within a danger level. Information on the causes supports comprehensibility in communication. ",
            "entrenchment": "Caution and restraint are required. Situation hardly changes. If possible, provide as precise information as possible on distribution and possibility to trigger. Increased communication of observations that reveal the situation and trigger restraint.",
            "free_ride": "Little text. Emphasise the generally favourable overall situation and potentially isolated hazard areas",
            "high_alert": "Communication across all channels. The focus is no longer on winter sports. Danger even in areas that are not usually at risk of avalanches.",
            "spring_diurnal": "Favourable situation in the morning, increasing during the day. Assessment should be made locally by observing the softening of the melt-freeze crust and the increasing soaking of the snow cover."
          }
        },
        "examples": {
          "title": "Examples:",
          "text": {
            "assessment": "Start of the season.",
            "stepping_out": "Cooling leads to a decrease in the risk of wet snow avalanches after a warm and humid weather phase.",
            "status_quo": "Consistent wind-slab problem with cold temperatures, loose snow and wind.",
            "stepping_back": "Abundant fresh snowfall leads to an increase in the danger situation.",
            "entrenchment": "Critical persistent weak layers problem with potential for dangerously large avalanches. The situation is only stabilising very slowly and requires a great deal of restraint.",
            "free_ride": "Low avalanche danger, no persistent weak layers, all terrain has already been groomed.",
            "high_alert": "Danger level 4 or higher due to large amounts of fresh snow and/or problematic, persistent weak layers. ",
            "spring_diurnal": "Spring situation with dry high-pressure weather and moderate temperatures with otherwise mostly stable old snow cover."
          }
        },
        "strategicMindset": {
          "assessment": "High Uncertainty",
          "stepping_out": "Improving",
          "status_quo": "Status Quo",
          "stepping_back": "Deteriorating",
          "entrenchment": "Persistent Unstable",
          "free_ride": "Favorable",
          "high_alert": "High Alert",
          "spring_diurnal": "Spring Diurnal"
        }
      },
      "decisionTree" : {
        "filepath": "./assets/img/decision_tree-en.svg",
        "feedbackURL": "https://admin.avalanche.report/umfrage-entscheidungsbaum/en/",
        "decisionTree" : "Decision tree",
        "feedback" : "Feedback",
        "save": "Save",
        "discard": "Discard"
      }
    },
    "table": {
      "title": {
        "date": "Date",
        "status": {
          "AT-02": "Carinthia",
          "AT-03": "Lower Austria",
          "AT-04": "Upper Austria",
          "AT-05": "Salzburg",
          "AT-06": "Styria",
          "AT-07": "Tyrol",
          "AT-08": "Vorarlberg",
          "CH" : "Switzerland",
          "DE-BY" : "Bavaria",
          "GEOSPHERE": "GEOSPHERE",
          "IT-32-TN": "Trentino",
          "IT-32-BZ": "South Tyrol",
          "ES-CT-L": "Aran"
        }
      },
      "status": {
        "missing": "missing",
        "draft": "draft",
        "submitted": "submitted",
        "published": "published",
        "updated": "updated",
        "resubmitted": "resubmitted",
        "republished": "republished"
      },
      "publishBulletinsDialog": {
        "message": "Do you want to publish the bulletin?",
        "missingDangerRating": "Danger rating not set",
        "missingRegion": "Regions not rated",
        "duplicateRegion": "Regions rated multiple times",
        "missingAvActivityHighlights": "Headline avalanche situation missing",
        "missingAvActivityComment": "Description of avalanche danger missing",
        "missingSnowpackStructureHighlights": "Headline snowpack structure missing",
        "missingSnowpackStructureComment": "Description of snowpack structure missing",
        "pendingSuggestions": "Pending suggestions",
        "incompleteTranslation": "Incomplete translation",
        "accept": "Yes",
        "reject": "No"
      },
      "publicationStatusDialog": {
        "headline": "Publication Status",
        "publishedBy": "Published by: ",
        "publishedAt": "Published at: ",
        "validFor": "Valid for: ",
        "title": {
          "prod": "Serious stuff",
          "test": "Test system",
          "task": "Task",
          "language": {
            "all": "ALL",
            "de": "DE",
            "it": "IT",
            "en": "EN"
          }
        },

        "caaml": {
          "label": "CAAML Files",
          "tooltip": "Create CAAML Files",
          "success": "{{prefix}}CAAML Files successfully created!",
          "error": "{{prefix}}CAAML Files could not be created!"
        },
        "map": {
          "label": "Maps",
          "tooltip": "Create Maps",
          "success": "{{prefix}}Maps successfully created!",
          "error": "{{prefix}}Maps could not be created!"
        },
        "pdf": {
          "label": "PDFs",
          "tooltip": "Create PDFs",
          "success": "{{prefix}}PDFs successfully created!",
          "error": "{{prefix}}PDFs could not be created!"
        },
        "html": {
          "label": "Simple HTMLs",
          "tooltip": "Create Simple HTML Files",
          "success": "{{prefix}}Simple HTML Files successfully created!",
          "error": "{{prefix}}Simple HTML Files could not be created!"
        },
        "email": {
          "label": "Emails",
          "tooltip": "Send Emails",
          "success": "{{prefix}}Emails successfully sent!",
          "error": "{{prefix}}Emails could not be sent!"
        },
        "telegram": {
          "label": "Telegram Messages",
          "tooltip": "Send Telegram Messages",
          "success": "{{prefix}}Telegram Messages successfully sent!",
          "error": "{{prefix}}Telegram Messages could not be sent!"
        },
        "push": {
          "label": "Push Notifications",
          "tooltip": "Send Push Notifications",
          "success": "{{prefix}}Push Notifications successfully sent!",
          "error": "{{prefix}}Push Notifications could not be sent!"
        },
        "accept": "Ok"
      },
      "mediaFileDialog": {
        "headline": "Media File",
        "important": "IMPORTANT",
        "missingFile": "No file selected!",
        "uploadError": "Upload failed!",
        "accept": "Send",
        "reject": "Cancel"
      },
      "publishBulletinsErrorDialog": {
        "message": "There was an error publishing the bulletins!",
        "accept": "Ok"
      },
      "submitBulletinsDialog": {
        "message": "Do you want to submit the bulletin?",
        "missingDangerRating": "Danger rating not set",
        "missingRegion": "Regions not rated",
        "missingAvActivityHighlights": "Headline avalanche situation missing",
        "missingAvActivityComment": "Description of avalanche danger missing",
        "missingSnowpackStructureHighlights": "Headline snowpack structure missing",
        "missingSnowpackStructureComment": "Description of snowpack structure missing",
        "pendingSuggestions": "Pending suggestions",
        "accept": "Yes",
        "reject": "No"
      },
      "submitBulletinsErrorDialog": {
        "message": "There was an error submitting the bulletins!",
        "accept": "Ok"
      },
      "submitBulletinsDuplicateRegionDialog": {
        "message": "Regions rated multiple times! Bulletin can not be submitted.",
        "accept": "Ok"
      },
      "checkBulletinsErrorDialog": {
        "message": "There was an error checking the bulletins!",
        "accept": "Ok"
      },
      "previewErrorDialog": {
        "message": "Preview could not be loaded!",
        "accept": "Ok"
      },
      "checkBulletinsDialog": {
        "message": "Check bulletin",
        "missingDangerRating": "Danger rating not set",
        "missingRegion": "Regions not rated",
        "missingAvActivityHighlights": "Headline avalanche situation missing",
        "missingAvActivityComment": "Description of avalanche danger missing",
        "missingSnowpackStructureHighlights": "Headline snowpack structure missing",
        "missingSnowpackStructureComment": "Description of snowpack structure missing",
        "pendingSuggestions": "Pending suggestions",
        "incompleteTranslation": "Incomplete translation",
        "ok": "(everything alright)",
        "accept": "Ok"
      },
      "publishAllDialog": {
        "message": "Do you want to publish all regions?",
        "accept": "Yes",
        "reject": "No"
      }
    }
  },
  "training": {
    "training": "Training",
    "timestamp": "Training timestamp"
  },
  "admin": {
    "loadingStatistics": "Creating CSV ...",
    "server-configuration": {
      "title": "Server",
      "button": {
        "create": "Create server",
        "save": "Save"
      },
      "table": {
        "name": "Name",
        "username": "Username",
        "password": "Password",
        "publishAt5PM": "Publish at 5PM",
        "publishAt8AM": "Publish at 8AM",
        "pdfDirectory": "Directory for PDF",
        "htmlDirectory": "Directory for HTML",
        "serverImagesUrl": "Path to server images",
        "mapsPath": "Path to maps",
        "mediaPath": "Path to media files",
        "mapProductionUrl": "URL for map production",
        "apiUrl": "API URL"
      },
      "success": "Configuration successfully saved",
      "error": "Configuration could not be saved"
    },
    "region-configuration": {
      "title": "Region",
      "button": {
        "create": "Create region",
        "save": "Save"
      },
      "table": {
        "general": {
          "title": "General",
          "id": "ID",
          "microRegions": "Number of micro regions",
          "subRegions": "Sub regions",
          "superRegions": "Super regions",
          "neighborRegions": "Neighbouring regions"
        },
        "publication": {
          "title": "Publication",
          "publishBulletins": "Publish avalanche forecast",
          "publishBlogs": "Publish blog posts",
          "createCaamlV5": "Create CAAML v5",
          "createCaamlV6": "Create CAAML v6",
          "createJson": "Create JSON",
          "createMaps": "Create maps",
          "createPdf": "Create PDF",
          "sendEmails": "Send emails",
          "createSimpleHtml": "Create simple HTML",
          "sendTelegramMessages": "Send telegram messages",
          "sendPushNotifications": "Send push notifications",
          "pdfColor": "PDF color",
          "emailColor": "Email color",
          "pdfMapYAmPm": "Y for PDF map (am/pm)",
          "pdfMapYFd": "Y for PDF map (fd)",
          "pdfMapWidthAmPm": "Map width for PDF (am/pm)",
          "pdfMapWidthFd": "Map width for PDF (fd)",
          "pdfMapHeight": "Map height for PDF",
          "pdfFooterLogo": "Logo for PDF footer",
          "pdfFooterLogoColorPath": "Logo for PDF footer (color)",
          "pdfFooterLogoBwPath": "Logo for PDF footer (bw)",
          "mapXmax": "Map X max",
          "mapXmin": "Map X min",
          "mapYmax": "Map Y max",
          "mapYmin": "Map Y min",
          "simpleHtmlTemplateName": "Simple HTML template",
          "geoDataDirectory": "Geodata directory",
          "mapLogoColorPath": "Logo for map (color)",
          "mapLogoBwPath": "Logo for map (bw)",
          "mapLogoPosition": "Logo position for map",
          "mapCenterLat": "Center position of map (lat)",
          "mapCenterLng": "Center position of map (long)",
          "imageColorbarColorPath": "Colorbar (color)",
          "imageColorbarBwPath": "Colorbar (b/w)"
        },
        "components": {
          "title": "Components",
          "enableDangerSources": "Enable danger sources",
          "enableObservations": "Enable observations",
          "enableModelling": "Enable modelling",
          "enableWeatherbox": "Enable weather box"
        },
        "configuration": {
          "title": "Configuration",
          "showMatrix": "Show matrix",
          "enableMediaFile": "Enable media file",
          "enableStrategicMindset": "Enable strategic mindset",
          "enableStressLevel": "Enable stress level",
          "enableAvalancheProblemCornices": "Enable avalanche problem CORNICES",
          "enableAvalancheProblemNoDistinctAvalancheProblem": "Enable avalanche problem NO DISTINCT AVALANCHE PROBLEM"
        }
      },
      "success": "Configuration successfully saved",
      "error": "Configuration could not be saved"
    },
    "users": {
      "title": "Users",
      "button": {
        "create": " Create user"
      },
      "tooltip": {
        "edit": "Edit user",
        "changePassword": "Change password",
        "delete": "Delete user"
      },
      "table": {
        "name": "Name",
        "email": "Email",
        "organization": "Organization",
        "regions": "Regions",
        "roles": "Roles"
      },
      "deleteUserModalDialog": {
        "message": "Do you want to delete the user?",
        "accept": "Yes",
        "reject": "No"
      },
      "deleteUser": {
        "success": "User deleted",
        "error": "User could not be deleted"
      },
      "createUser": {
        "success": "User created",
        "error": "User could not be created"
      },
      "updateUser": {
        "success": "User updated",
        "error": "User could not be updated"
      }
    },
    "createUser": {
      "table": {
        "image": "Image",
        "name": "Name",
        "email": "Email",
        "organization": "Organization",
        "password": "Password",
        "password2": "Repeat password",
        "roles": "Roles",
        "regions": "Regions"
      },
      "error": {
        "name": {
          "required": "Please enter a name"
        },
        "email": {
          "required": "Please enter an email address",
          "invalid": "Email address is invalid"
        },
        "password": {
          "required": "Please enter a password",
          "length": "Password has to be at least 8 characters",
          "mismatch": "Passwords do not match"
        }
      },
      "button": {
        "create": "Create user",
        "update": "Update user",
        "cancel": "Cancel"
      }
    },
    "observations": {
      "table": "Table",
      "map": "Map",
      "gallery": "Gallery",
      "filters": "Filters",
      "title": "Observations",
      "newObservation": "New observation",
      "exportObservations": "Export observations",
      "toggleFilterBar": "Show/hide filter bar",
      "button": {
        "submit": "Download"
      },
      "label": {
        "dateRange": "Period",
        "observer": "Observer"
      },
      "selectRegion": {
        "placeholder": "Region",
        "selectionLabel": "{0} region(s) selected"
      },
      "selectSources": {
        "placeholder": "Sources",
        "selectionLabel": "{0} source(s) selected"
      },
      "charts": {
        "charts": "Charts",
        "nan": "Without specification",
        "classify": "Classify",
        "label": "Label",
        "invert": "Invert",
        "reset": "Reset",
        "aspect": {
          "caption": "Aspect"
        },
        "elevation": {
          "caption": "Elevation"
        },
        "stability": {
          "caption": "Stability"
        },
        "observationType": {
          "caption": "Observation Type"
        },
        "importantObservation": {
          "caption": "Important"
        },
        "avalancheProblem": {
          "caption": "Avalanche Problem"
        },
        "dangerPattern": {
          "caption": "Danger Pattern"
        },
        "days": {
          "caption": "Day"
        }
      }
    },
    "blog": {
      "title": "Blog",
      "language": {
        "title": "Language",
        "de": "DE",
        "it": "IT",
        "en": "EN"
      },
      "all": {
        "tooltip": "Send Emails, Telegram and Push",
        "success": "{{prefix}}Email, Telegram and Push successfully sent!",
        "error": "{{prefix}}Email, Telegram and Push could not be sent!"
      },
      "email": {
        "tooltip": "Send Emails",
        "success": "{{prefix}}Email successfully sent!",
        "error": "{{prefix}}Email could not be sent!"
      },
      "telegram": {
        "tooltip": "Send via Telegram Channel",
        "success": "{{prefix}}Telegram successfully sent!",
        "error": "{{prefix}}Telegram could not be sent!"
      },
      "push": {
        "tooltip": "Send via Push Notifications",
        "success": "{{prefix}}Push successfully sent!",
        "error": "{{prefix}}Push could not be sent!"
      }
    }
  },
  "settings": {
    "title": "Settings",
    "changePassword": {
      "title": "Change password",
      "oldPassword": {
        "label": "Current password",
        "placeholder": "Current password"
      },
      "newPassword1": {
        "label": "New password",
        "placeholder": "New password"
      },
      "newPassword2": {
        "label": "New password (repeat)",
        "placeholder": "New password"
      },
      "submit": "Change password",
      "passwordChanged": "Password successfully changed",
      "passwordChangeError": "Password could not be changed",
      "passwordIncorrect": "Current password incorrect",
      "error": {
        "oldPassword": {
          "required": "Please enter your current password"
        },
        "newPassword": {
          "required": "Please enter a new password",
          "mismatch": "Passwords do not match"
        }
      }
    },
    "display": {
      "title": "Display",
      "table": {
        "showCaaml": "Show CAAML",
        "showJson": "Show JSON"
      }
    }
  },
  "statistics": {
    "title": "Statistics",
    "button": {
      "submit": "Download"
    },
    "label": {
      "dateRange": "Period",
      "extended": "Extended",
      "duplicates": "Duplicates"
    }
  },

  "dangerRating": {
    "noRating": "no rating",
    "low": "low",
    "moderate": "moderate",
    "considerable": "considerable",
    "high": "high",
    "veryHigh": "very high"
  },
  "avalancheProblem": {
    "newSnow": "New snow",
    "new_snow": "New snow",
    "windSlab": "Wind slab",
    "wind_slab": "Wind slab",
    "persistentWeakLayers": "Persistent weak layers",
    "persistent_weak_layers": "Persistent weak layers",
    "wetSnow": "Wet snow",
    "wet_snow": "Wet snow",
    "glidingSnow": "Gliding snow",
    "gliding_snow": "Gliding snow",
    "cornices": "Cornices",
    "noDistinctAvalancheProblem": "No distinct avalanche problem",
    "no_distinct_avalanche_problem": "No distinct avalanche problem",
    "favourableSituation": "Favourable situation",
    "favourable_situation": "Favourable situation"
  },
  "dangerPattern": {
    "dp1": "dp.1: deep persistent weak layer",
    "dp2": "dp.2: gliding avalanches",
    "dp3": "dp.3: rain",
    "dp4": "dp.4: cold following warm / warm following cold",
    "dp5": "dp.5: snowfall after a long period of cold",
    "dp6": "dp.6: cold, loose snow and wind",
    "dp7": "dp.7: snow-poor zones in snow-rich surrounding",
    "dp8": "dp.8: buried surface hoar",
    "dp9": "dp.9: buried graupel",
    "dp10": "dp.10: springtime scenario"
  },
  "strategicMindset": {
    "title": "Strategic Mindset",
    "assessment": "High Uncertainty",
    "stepping_out": "Improving",
    "status_quo": "Status Quo",
    "stepping_back": "Deteriorating",
    "entrenchment": "Persistent Unstable",
    "free_ride": "Favorable",
    "high_alert": "High Alert",
    "spring_diurnal": "Spring Diurnal"
  },
  "observationType": {
    "TimeSeries": "Time series",
    "Incident": "Incident",
    "Profile": "Snow profile",
    "Closure": "Closure",
    "Blasting": "Blasting",
    "Avalanche": "Avalanche",
    "Evaluation": "Evaluation",
    "SimpleObservation": "Simple observation",
    "Webcam": "Webcam"
  },
  "importantObservation": {
    "SnowLine": "Snow line",
    "SurfaceHoar": "Surface hoar",
    "Graupel": "Graupel",
    "StabilityTest": "Stability test",
    "IceFormation": "Ice formation",
    "VeryLightNewSnow": "Very light new snow"
  },
  "aspect": {
    "N": "N",
    "NE": "NE",
    "E": "E",
    "SE": "SE",
    "S": "S",
    "SW": "SW",
    "W": "W",
    "NW": "NW"
  },
  "role": {
    "SUPERADMIN": "Super Administrator",
    "ADMIN": "Administrator",
    "FORECASTER": "Forecaster",
    "FOREMAN": "Foreman",
    "OBSERVER": "Observer"
  },
  "tendency": {
    "decreasing": " Avalanche danger decreases",
    "steady": " Avalanche danger stays the same",
    "increasing": " Avalanche danger increases"
  },
  "snowpackStability": {
    "unknown": "unknown",
    "good": "good",
    "fair": "fair",
    "poor": "poor",
    "very_poor": "very poor"
  },
  "frequency": {
    "none": "(nearly) none",
    "few": "a few",
    "some": "some",
    "many": "many"
  },
  "avalancheSize": {
    "small": "small",
    "medium": "medium",
    "large": "large",
    "very_large": "very large",
    "extreme": "extreme"
  },
  "complexity": {
    "easy": " Easy",
    "challenging": " Challenging",
    "complex": " Complex"
  },
  "glidingSnowActivity": {
    "low": "low",
    "medium": "medium",
    "high": "high"
  },
  "wetness": {
    "dry": "dry",
    "moist": "moist",
    "wet": "wet"
  },
  "characteristic": {
    "low": "low",
    "medium": "medium",
    "high": "high",
    "very_high": "very high"
  },
  "thickness": {
    "thin": "thin",
    "thick": "thick"
  },
  "snowpackPosition": {
    "upper": "upper",
    "middle": "middle",
    "lower": "lower",
    "ground": "ground"
  },
  "creationProcess": {
    "radiation_recrystallization": "radiation",
    "diurnal_recrystallization": "diurnal",
    "melt_layer_recrystallization": "melt layer"
  },
  "recognizability": {
    "very_easy": "very easy",
    "easy": "easy",
    "hard": "hard",
    "very_hard": "very hard"
  },
  "distribution": {
    "isolated": "isolated",
    "specific": "specific",
    "widespread": "widespread"
  },
  "probability": {
    "likely": "likely",
    "possible": "possible",
    "unlikely": "unlikely"
  },
  "detailedDaytime": {
    "evening": "evening",
    "first_night_half": "first night half",
    "second_night_half": "second night half",
    "morning": "morning",
    "forenoon": "forenoon",
    "afternoon": "afternoon"
  },
  "slopeGradient": {
    "moderately_steep": "moderately steep",
    "steep": "steep",
    "very_steep": "very steep",
    "extremely_steep": "extremely steep"
  },
  "handHardness": {
    "fist": "F",
    "four_fingers": "4F",
    "one_finger": "1F",
    "pencil": "P",
    "knife": "K",
    "ice": "I"
  },
  "hardnessTendency": {
    "decreasing": "decreasing with height",
    "steady": "uniform",
    "increasing": "increasing with height"
  },
  "terrainType": {
    "gullies": "gullies",
    "bowls": "bowls",
    "pass_areas": "pass areas",
    "ridge_lines": "ridge lines"
  },
  "dangerSign": {
    "shooting_cracks": "shooting cracks",
    "whumpfing": "whumpfing"
  },
  "changeRegionDialog": {
    "message": "Do you want to change the region?",
    "accept": "Yes",
    "reject": "No"
  },
  "zamg": {
    "table": "Table",
    "map": "Map",
    "region": "Region",
    "newSnow": "New snow"
  },
  "snowpack": {
    "plotType": {
      "LWC_stratigraphy": "wet snow: liquid water content",
      "wet_snow_instability": "wet snow: wet snow instability",
      "Sk38_stratigraphy": "persistent weak layers: SK38 stability index",
      "stratigraphy": "persistent weak layers: snow stratigraphy"
    },
    "aspect": {
      "flat": "flat",
      "north": "north",
      "south": "south"
    },
    "meteo": {
      "new_snow_plot_3day": "new snow (3d)",
      "new_snow_plot_7day": "new snow (7d)",
      "new_snow_plot_1month": "new snow (31d)",
      "new_snow_plot_season": "new snow (season)",
      "new_snow_plot_forecast": "new snow (forecast)",
      "wet_snow_plot_3day": "wet snow (3d)",
      "wet_snow_plot_7day": "wet snow (7d)",
      "wet_snow_plot_1month": "wet snow (31d)",
      "wet_snow_plot_season": "wet snow (season)",
      "wet_snow_plot_forecast": "wet snow (forecast)",
      "HS_table_24h": "snow height table (1d)",
      "HS_table_72h": "snow height table (3d)",
      "HS_table_season": "snow height table (season)",
      "HS_table_forecast": "snow height table (forecast)",
      "TA_table_24h": "temperature table (1d)",
      "TA_table_72h": "temperature table (3h)",
      "TA_table_season": "temperature table (season)"
    }
  },
  "grainShape": {
    "PP": {
      "code": "PP",
      "class": "Precipitation Particles"
    },
    "PPgp": {
      "code": "PPgp",
      "class": "Graupel"
    },
    "DF": {
      "code": "DF",
      "class": "Decomposing and Fragmented precipitation particles"
    },
    "RG": {
      "code": "RG",
      "class": "Rounded Grains"
    },
    "FC": {
      "code": "FC",
      "class": "Faceted Crystals"
    },
    "DH": {
      "code": "DH",
      "class": "Depth Hoar"
    },
    "SH": {
      "code": "SH",
      "class": "Surface Hoar"
    },
    "MF": {
      "code": "MF",
      "class": "Melt Forms"
    },
    "MFcr": {
      "code": "MFcr",
      "class": "Melt-freeze crust"
    },
    "IF": {
      "code": "IF",
      "class": "Ice Formations"
    }
  },
  "button": {
    "yes": "yes",
    "no": "no"
  },
  "observations": {
    "aspect": "Aspect",
    "authorName": "Author name",
    "avalancheLength": "Avalanche length",
    "avalancheProblem": "Avalanche problem",
    "avalancheSize": "Avalanche size",
    "avalancheType": "Avalanche type",
    "avalancheWidth": "Avalanche width",
    "search": "Search",
    "count": "# observations:",
    "layers": {
      "observers": "Observers",
      "weatherStations": "Weather stations",
      "webcams": "Webcams",
      "observations": "Observations"
    },
    "weatherStations": {
      "tooltips": {
        "globalRadiation": "Global radiation",
        "snowHeight": "Snow height",
        "snowDifference24h": "Snow difference 24h",
        "snowDifference48h": "Snow difference 48h",
        "snowDifference72h": "Snow difference 72h",
        "airTemperature": "Air temperature",
        "airTemperatureMax": "Air temperature Max",
        "airTemperatureMin": "Air temperature Min",
        "surfaceTemperature": "Surface temperature",
        "surfaceHoar": "Potential surface hoar formation",
        "surfaceHoarCalc": "Potential surface hoar formation (Lehning et. al.)",
        "dewPoint": "Dew point temperature",
        "relativeHumidity": "Relative humidity",
        "windDirection": "Wind direction",
        "windSpeed": "Wind speed",
        "windGust": "Wind gust"
      }
    },
    "button": {
      "add": "Add observation",
      "save": "Save observation",
      "delete": "Delete observation",
      "deleteConfirm": "Delete observation?",
      "discard": "Discard"
    },
    "comment": "Comment",
    "content": "Content",
    "dangerRating": "Danger rating",
    "elevation": "Elevation",
    "elevationRange": "Elevation range",
    "elevationLowerBound": "Elevation lower bound",
    "elevationUpperBound": "Elevation upper bound",
    "eventDate": "Event date",
    "eventType": "Event type",
    "eventTypes": {
      "IMPORTANT": "Important event",
      "NEIGHBOR_REGION": "Neighbouring region",
      "NORMAL": "Normal event",
      "PERSON_DEAD": "Event involving persons (dead)",
      "PERSON_INJURED": "Event involving persons (injured)",
      "PERSON_NO": "Event not involving persons",
      "PERSON_UNINJURED": "Event involving persons (uninjured)",
      "PERSON_UNKNOWN": "Event (unknown)",
      "TRAFFIC": "Traffic"
    },
    "fractureDepth": "Fracture depth",
    "hasCoordinates": "Has coordinates",
    "incline": "Incline",
    "latitude": "Latitude",
    "locationName": "Location",
    "longitude": "Longitude",
    "personInvolvement": "Person involvement",
    "reportDate": "Report date",
    "showTable": "Table",
    "withoutCoordinates": "Observations without coordinates:",
    "sidebar": {
      "filter": {
        "title": "Filter"
      },
      "legend": {
        "title": "Legend"
      },
      "sources": {
        "title": "Data Sources"
      },
      "views": {
        "title": "Views"
      }
    }
  },
  "personInvolvement": {
    "Dead": "Event involving persons (dead)",
    "Injured": "Event involving persons (injured)",
    "No": "Event not involving persons",
    "Uninjured": "Event involving persons (uninjured)",
    "Unknown": "Event (unknown)"
  },
  "units": {
    "cm": "cm",
    "mm": "mm",
    "m": "m"
  },
  "qfa": {
    "day": "Day",
    "time": "Time",
    "back": " Back",
    "parameters": {
      "DUST": "sahara dust",
      "N": "total cloud cover in /8",
      "Nh": "high cloud cover in /8",
      "Nm": "medium high cloud cover in /8",
      "Nl": "low cloud cover in /8",
      "N-CU": "cumulus cloud cover in /8",
      "AUSL.T": "trigger temperature",
      "SHW-IX": "Showalter-Index",
      "WX -CUF": "significant weather: NIL (none), RASH (rain), TS (thunder storm)",
      "RR": "total precipitation [mm]",
      "RR-str": "stratiform precipitation",
      "CONV": "convective precipitation [mm]",
      "SN": "precipitation in form of snow [mm]",
      "SN--RA": "snowline [m]",
      "QAO ": "{{value}} hPa eq. mean wind, direction and strength, [°] and [m/s]",
      "QAN": "mean wind on the ground, direction and strength, [°] and [m/s]",
      "QANmax": "max. wind (gusts) on the ground [m/s]",
      "T ": "{{value}} hPa eq. temperature [°C]",
      "T m": "temperature on {{value}}m [°C]",
      "T 2m": "temperature on 2m [°C]",
      "Tk 2m": "temperature on 2m [°K]",
      "T GND": "ground temperature",
      "Max-ADI": "maximum mixing temperature [°C]",
      "Min Max": "min./max. temperature in the last 24h",
      "FROST": "zero degree line [m]",
      "W cm": "500 hPa eq. vertical wind [cm/s]",
      "W --": "700 hPa eq. vertical wind [cm/s]",
      "W s": "{{value}} hPa eq. vertical wind [cm/s]",
      "H ": "{{value}} hPa geopotential height [m]",
      "RF ": "{{value}} hPa eq. relative moisture [%]"
    }
  }
}
